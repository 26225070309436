import React, {useEffect, useState} from "react";
import Audience from "./Audience";
import Objectives from "./Objectives";
import Preview from "./Preview";
import SalesPitch from "./SalesPitch";
import "react-loading-skeleton/dist/skeleton.css";
import InfoBoxWithLogo from "../../../../components/AiRunning";
import WebsiteSearchInput from "../../../Account/components/WebsiteSearchInput";

const MainBanner = () => {
    const [companyWebsite, setCompanyWebsite] = useState("");
    const [companyData, setCompanyDate] = useState(null);
    const [crawling, setCrawling] = useState(false);
    const [dataFetched, setDataFetched] = useState(false);

    const views = ["Audience", "Objectives", "Preview", "SalesPitch"];
    const [currentView, setCurrentView] = useState("Audience");
    const [crawlingMessage, setCrawlingMessage] = useState("");

    const componentsMap = {
        Audience: Audience,
        Objectives: Objectives,
        Preview: Preview,
        SalesPitch: SalesPitch
    }

    useEffect(() => {

        const crawlingMessages = [
            "Brewing your pitch ☕️! AI is scanning your site. Almost done!",
            "Dialing up charm 📞! AI is crafting your outreach. Hang tight!",
            "AI on the case 🕵️‍♀️🔍! Analyzing your site for a killer campaign!",
            "Spinning the wheel 🎡! AI is building your cold outreach. One sec!",
            "Cooking magic 🪄✨! AI is crafting a spellbinding campaign!"
        ];

        setCrawlingMessage(crawlingMessages[Math.floor(Math.random() * crawlingMessages.length)])
    }, [])

    const CurrentComponent = componentsMap[currentView];

    return (
        <div className="main-banner__block position-relative">
            <div className="d-flex w-100 justify-content-center title">
                <svg xmlns="http://www.w3.org/2000/svg" width="244" height="30" viewBox="0 0 244 30" fill="none">
                    <path
                        d="M182.461 7.91899V12.2643H169.9V7.91899H182.461ZM172.752 2.92188H178.537V22.3672C178.537 22.9013 178.618 23.3177 178.781 23.6165C178.944 23.9061 179.17 24.1098 179.46 24.2275C179.759 24.3452 180.103 24.404 180.492 24.404C180.764 24.404 181.035 24.3814 181.307 24.3361C181.578 24.2818 181.787 24.2411 181.931 24.2139L182.841 28.5185C182.552 28.609 182.144 28.7132 181.619 28.8308C181.094 28.9576 180.456 29.0345 179.705 29.0617C178.31 29.116 177.088 28.9304 176.038 28.5049C174.997 28.0795 174.187 27.4186 173.607 26.5224C173.028 25.6262 172.743 24.4946 172.752 23.1276V2.92188Z"
                        fill="#232F5E"/>
                    <path
                        d="M157.342 29.1849C155.233 29.1849 153.409 28.7368 151.87 27.8406C150.34 26.9353 149.158 25.677 148.325 24.0656C147.493 22.4452 147.076 20.5667 147.076 18.4303C147.076 16.2757 147.493 14.3927 148.325 12.7813C149.158 11.1609 150.34 9.90257 151.87 9.00635C153.409 8.10107 155.233 7.64844 157.342 7.64844C159.451 7.64844 161.271 8.10107 162.801 9.00635C164.34 9.90257 165.526 11.1609 166.359 12.7813C167.191 14.3927 167.608 16.2757 167.608 18.4303C167.608 20.5667 167.191 22.4452 166.359 24.0656C165.526 25.677 164.34 26.9353 162.801 27.8406C161.271 28.7368 159.451 29.1849 157.342 29.1849ZM157.369 24.7038C158.329 24.7038 159.13 24.4322 159.773 23.8891C160.415 23.3368 160.9 22.5855 161.226 21.6349C161.561 20.6844 161.728 19.6026 161.728 18.3895C161.728 17.1765 161.561 16.0947 161.226 15.1441C160.9 14.1936 160.415 13.4422 159.773 12.89C159.13 12.3378 158.329 12.0617 157.369 12.0617C156.401 12.0617 155.586 12.3378 154.925 12.89C154.273 13.4422 153.78 14.1936 153.445 15.1441C153.119 16.0947 152.956 17.1765 152.956 18.3895C152.956 19.6026 153.119 20.6844 153.445 21.6349C153.78 22.5855 154.273 23.3368 154.925 23.8891C155.586 24.4322 156.401 24.7038 157.369 24.7038Z"
                        fill="#232F5E"/>
                    <path
                        d="M122.744 28.7788V0.96875H133.879C135.925 0.96875 137.631 1.27202 138.998 1.87855C140.365 2.48509 141.393 3.32699 142.081 4.40427C142.769 5.47249 143.113 6.70367 143.113 8.09779C143.113 9.18412 142.896 10.1392 142.461 10.963C142.026 11.7777 141.429 12.4476 140.669 12.9727C139.917 13.4887 139.057 13.8553 138.089 14.0726V14.3442C139.148 14.3894 140.139 14.6882 141.062 15.2404C141.995 15.7926 142.751 16.5666 143.33 17.5624C143.909 18.5492 144.199 19.726 144.199 21.093C144.199 22.5686 143.833 23.8858 143.099 25.0445C142.375 26.1942 141.302 27.104 139.881 27.7739C138.46 28.4438 136.708 28.7788 134.626 28.7788H122.744ZM128.624 23.9718H133.417C135.056 23.9718 136.251 23.6595 137.002 23.0348C137.754 22.4011 138.129 21.5592 138.129 20.5091C138.129 19.7396 137.944 19.0607 137.573 18.4722C137.201 17.8838 136.672 17.4221 135.984 17.0872C135.305 16.7522 134.495 16.5847 133.553 16.5847H128.624V23.9718ZM128.624 12.6061H132.983C133.788 12.6061 134.504 12.4657 135.128 12.1851C135.762 11.8954 136.26 11.488 136.622 10.963C136.993 10.4379 137.179 9.80876 137.179 9.07549C137.179 8.07063 136.821 7.26041 136.106 6.64482C135.4 6.02924 134.395 5.72144 133.091 5.72144H128.624V12.6061Z"
                        fill="#232F5E"/>
                    <path
                        d="M109.287 29.1849C107.141 29.1849 105.294 28.7504 103.746 27.8813C102.207 27.0032 101.022 25.763 100.189 24.1606C99.3559 22.5493 98.9395 20.6437 98.9395 18.4438C98.9395 16.2983 99.3559 14.4154 100.189 12.7949C101.022 11.1745 102.194 9.91162 103.706 9.00635C105.227 8.10107 107.01 7.64844 109.056 7.64844C110.432 7.64844 111.713 7.87023 112.899 8.31381C114.094 8.74835 115.135 9.40467 116.022 10.2828C116.918 11.1609 117.615 12.2653 118.113 13.5961C118.611 14.9178 118.86 16.4658 118.86 18.2402V19.8289H101.248V16.244H113.415C113.415 15.4112 113.234 14.6734 112.872 14.0306C112.51 13.3879 112.007 12.8855 111.364 12.5233C110.731 12.1522 109.993 11.9666 109.151 11.9666C108.273 11.9666 107.494 12.1703 106.815 12.5777C106.145 12.976 105.62 13.5146 105.24 14.1936C104.86 14.8635 104.665 15.6103 104.656 16.4341V19.8425C104.656 20.8745 104.846 21.7662 105.227 22.5176C105.616 23.269 106.164 23.8483 106.87 24.2557C107.576 24.6631 108.413 24.8668 109.382 24.8668C110.025 24.8668 110.613 24.7762 111.147 24.5952C111.681 24.4141 112.138 24.1425 112.519 23.7804C112.899 23.4183 113.188 22.9747 113.388 22.4497L118.738 22.8027C118.466 24.0882 117.909 25.2108 117.068 26.1704C116.235 27.1209 115.157 27.8632 113.836 28.3973C112.523 28.9224 111.007 29.1849 109.287 29.1849Z"
                        fill="#232F5E"/>
                    <path d="M95.148 0.96875V28.7788H89.3633V0.96875H95.148Z" fill="#232F5E"/>
                    <path
                        d="M85.1486 7.91899V12.2643H72.5879V7.91899H85.1486ZM75.4395 2.92188H81.2242V22.3672C81.2242 22.9013 81.3057 23.3177 81.4686 23.6165C81.6316 23.9061 81.8579 24.1098 82.1476 24.2275C82.4463 24.3452 82.7903 24.404 83.1796 24.404C83.4512 24.404 83.7228 24.3814 83.9944 24.3361C84.2659 24.2818 84.4742 24.2411 84.619 24.2139L85.5288 28.5185C85.2391 28.609 84.8317 28.7132 84.3067 28.8308C83.7816 28.9576 83.1434 29.0345 82.392 29.0617C80.9979 29.116 79.7758 28.9304 78.7257 28.5049C77.6846 28.0795 76.8744 27.4186 76.295 26.5224C75.7156 25.6262 75.4305 24.4946 75.4395 23.1276V2.92188Z"
                        fill="#232F5E"/>
                    <path
                        d="M69.9357 13.8677L64.6398 14.1936C64.5493 13.7409 64.3547 13.3336 64.0559 12.9715C63.7572 12.6003 63.3634 12.3061 62.8746 12.0888C62.3948 11.8625 61.8199 11.7493 61.15 11.7493C60.2538 11.7493 59.4979 11.9394 58.8823 12.3197C58.2667 12.6908 57.9589 13.1887 57.9589 13.8134C57.9589 14.3113 58.1581 14.7322 58.5564 15.0762C58.9547 15.4202 59.6382 15.6963 60.6068 15.9045L64.3818 16.665C66.4097 17.0814 67.9215 17.7513 68.9173 18.6747C69.9131 19.5981 70.411 20.8111 70.411 22.3139C70.411 23.6809 70.0081 24.8803 69.2024 25.9124C68.4058 26.9444 67.3104 27.7501 65.9163 28.3294C64.5312 28.8998 62.9334 29.1849 61.1228 29.1849C58.3618 29.1849 56.1619 28.6101 54.5234 27.4604C52.8939 26.3016 51.9388 24.7264 51.6582 22.7348L57.3479 22.4361C57.5199 23.278 57.9363 23.9208 58.5971 24.3643C59.258 24.7989 60.1044 25.0161 61.1364 25.0161C62.1503 25.0161 62.9651 24.8215 63.5807 24.4322C64.2053 24.0339 64.5222 23.5224 64.5312 22.8978C64.5222 22.3727 64.3004 21.9427 63.8658 21.6078C63.4313 21.2638 62.7614 21.0012 61.8561 20.8202L58.2441 20.1005C56.2072 19.6931 54.6909 18.987 53.6951 17.9822C52.7083 16.9773 52.2149 15.6963 52.2149 14.1393C52.2149 12.7995 52.5771 11.6452 53.3013 10.6766C54.0345 9.70794 55.062 8.96109 56.3837 8.43602C57.7145 7.91097 59.2716 7.64844 61.055 7.64844C63.6893 7.64844 65.7624 8.20518 67.2742 9.31867C68.7951 10.4322 69.6822 11.9485 69.9357 13.8677Z"
                        fill="#232F5E"/>
                    <path
                        d="M42.1684 19.8987V7.92188H47.9531V28.7794H42.3992V24.9908H42.182C41.7112 26.213 40.9282 27.1952 39.8328 27.9375C38.7465 28.6798 37.4202 29.051 35.8541 29.051C34.46 29.051 33.2333 28.7341 32.1742 28.1004C31.115 27.4668 30.2867 26.566 29.6892 25.3982C29.1008 24.2304 28.802 22.8317 28.793 21.2023V7.92188H34.5777V20.1702C34.5867 21.4014 34.9172 22.3746 35.5689 23.0898C36.2207 23.8049 37.0943 24.1625 38.1897 24.1625C38.8868 24.1625 39.5386 24.0041 40.1451 23.6872C40.7516 23.3613 41.2405 22.8815 41.6117 22.2478C41.9919 21.6142 42.1775 20.8311 42.1684 19.8987Z"
                        fill="#232F5E"/>
                    <path
                        d="M0.373047 28.7788V0.96875H6.25281V12.4431H18.1889V0.96875H24.055V28.7788H18.1889V17.2909H6.25281V28.7788H0.373047Z"
                        fill="#232F5E"/>
                    <circle cx="195.703" cy="14.5522" r="4.55224" fill="#232F5E"/>
                    <path d="M243.627 0V28.0565H236.01V0H243.627Z" fill="#232F5E"/>
                    <path
                        d="M212.593 28.0565H204.373L213.634 0H224.045L233.306 28.0565H225.087L218.949 7.72649H218.73L212.593 28.0565ZM211.058 16.9873H226.511V22.6863H211.058V16.9873Z"
                        fill="#377DFF"/>
                </svg>
            </div>
            <div className="d-flex justify-content-center description mt-4" style={{
                maxWidth: "956px"
            }}>
                <center>
                    The World’s Most <highlight-text>Advanced</highlight-text> Cold Outreach AI Agent
                </center>
            </div>
            <div className="d-flex w-100 justify-content-center sub-description">
                Your outbound campaign is 1 min away
            </div>
            {/*<div className="w-100 sub-description">*/}
            {/*    <div className="d-flex justify-content-center">*/}
            {/*        Your outbound campaign is 1 min away*/}
            {/*    </div>*/}
            {/*    /!*<div className="d-flex justify-content-center free-text">*!/*/}
            {/*    /!*    First Month Free*!/*/}
            {/*    /!*</div>*!/*/}
            {/*</div>*/}

            <div className="w-100">
                <div className="hustle__block d-flex justify-content-center w-100">
                    <div className="position-relative">
                        <WebsiteSearchInput
                            crawling={crawling}
                            setCrawling={setCrawling}
                            setDataFetched={setDataFetched}
                        />
                        {
                            crawling && (<div className="main-banner-info__container pl-3 pr-3">
                                <InfoBoxWithLogo text={crawlingMessage} dataFetched={dataFetched}/>
                            </div>)
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MainBanner;