import React, {useContext, useEffect, useState} from "react";
import {ReactTyped} from "react-typed";
import Swal from "sweetalert2";
import {isValidWebsite} from "../../../main/helpers";
import * as actions from "../../../main/actions/Account";
import accountRouteList from "../../../main/routes/Account";
import mixpanel from "mixpanel-browser";
import {AppContext} from "../../../main/context";

const WebsiteSearchInput = ({
                                crawling,
                                setCrawling,
                                setDataFetched,
                            }) => {

    const [companyWebsite, setCompanyWebsite] = useState("");
    const [state, dispatch] = useContext(AppContext);
    const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 768);
    const [placeholder, setPlaceholder] = useState(isMobileView ? "Enter website":"Enter your website to continue");

    useEffect(() => {
        const handleResize = () => {
            setIsMobileView(window.innerWidth <= 768);
        };

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    })

    useEffect(() => {
        setPlaceholder(isMobileView ? "Enter website":"Enter your website to continue");
    }, [isMobileView]);

    const handleCrawl = () => {
        let websiteToCrawl = `https://${companyWebsite}`;
        mixpanel.track('Website Submitted on Homepage', {
            website: websiteToCrawl
        });

        if (isValidWebsite(websiteToCrawl) && !crawling) {
            setCrawling(true);

            actions.startWebsiteCrawling(websiteToCrawl).then((res) => {
                if (res.data.success && res.data.body?.landing_page_company_name) {
                    localStorage.setItem("scraped_campaign_data", JSON.stringify({
                        ...res.data.body,
                        landing_page_url: websiteToCrawl
                    }));
                    localStorage.setItem("redirect_from_landing_page", true);
                    setDataFetched(true);

                    setTimeout(() => {
                        setCrawling(false);
                        window.location.href = accountRouteList.campaign_manager.new;
                    }, 2000);
                } else {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "We couldn't find any data on this website. Please try again."
                    });
                    setCrawling(false);
                }
            });
        }
    };

    return (
        <div className="w-100 d-flex justify-content-center">
            <div className={"search-website__input__container position-relative p-2" + (state.home.focusWebsiteField? " focus": "")} style={{
                borderRadius: "120px"
                }}>
                {crawling && <div className="running-border"></div>}

                <div className="search-website__input d-flex align-items-center position-relative">
                    {/* Prefix */}
                    <div className="http-text" style={{flex: "1.3"}}>
                        <b>https://</b>
                    </div>

                    {/* Input Field */}
                    <div className="d-flex justify-content-center position-relative" style={{flex: "4"}}>
                        <ReactTyped
                            strings={[placeholder]}
                            typeSpeed={40}
                            backSpeed={50}
                            attr="placeholder"
                            showCursor={true}
                            className="w-100"
                        >
                            <input
                                type="url"
                                autoComplete="off"
                                autoCapitalize="none"
                                className="w-100 pl-1 focus:outline-none focus:ring-0 caret-transparent"
                                value={companyWebsite}
                                onFocus={() => setPlaceholder("")}
                                onBlur={() => setPlaceholder(isMobileView ? "Enter website" : "Enter your website to continue")}
                                onChange={(e) => setCompanyWebsite(e.target.value)}
                                onKeyDown={(e) => e.key === "Enter" && handleCrawl()}
                                style={{
                                    border: "none",
                                    outline: "none",
                                    height: "50px",
                                    textAlign: "center"
                                }}
                            />
                        </ReactTyped>
                        {
                            (companyWebsite.length > 0 && !crawling) &&
                            <span
                                onClick={() => {
                                    setCompanyWebsite("");
                                }}
                                className="clear-website position-absolute h-100"
                                style={{
                                    right: 0,
                                    cursor: "pointer",
                                }}>
                                &#10005;
                            </span>
                        }
                    </div>

                    {/* Go Button */}
                    <div
                        className="d-flex justify-content-end"
                        style={{flex: "2", cursor: "pointer"}}
                        onClick={handleCrawl}
                    >
                        <div className="go-btn d-flex justify-content-center align-items-center pl-3 pr-3">
                            Start Trial
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WebsiteSearchInput;
