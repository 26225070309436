import React from "react";
import parse from "html-react-parser";

const Pricing = () => {

    const features = [
        "Free trial",
        "5x Cheaper than alternatives",
        "Dedicated SDR",
        "A/B Tested Copy powered by AI",
        "Highly Targeted Campaigns <br/> with our proprietary dataset",
        "Qualified Conversations sent <br/> to your inbox"
    ]
    return (
        <div className="pricing__block ml-0 mr-0">
            <div className="pricing d-flex align-items-center justify-center-center">
                <div className="pricing-description__block">
                    <div className="header">
                        Pricing to suit all teams
                    </div>
                    <div className="mt-3 description">
                        Save time prospecting. HustleBot identifies the champions, influencers, and decision makers at
                        your target accounts who buy things.
                    </div>
                </div>
            </div>
            <div className="campaign-automation d-flex align-items-center justify-content-center">
                <div className="block position-relative" style={{
                    padding: "40px"
                }}>
                    <div className="position-absolute" style={{
                        right: 0,
                        top: 0,
                        width: "200px",
                        height: "200px",
                        opacity: 0.8,
                        background: "radial-gradient(circle at right top, rgba(55, 125, 255, 0.3) 20%, rgba(98, 212, 243, 0.4) 50%, transparent 70%)",
                        borderTopRightRadius: "33px"
                    }}>

                    </div>
                    <div className="header d-flex justify-content-center">
                        Hire your AI SDR Today!
                    </div>
                    <div className="d-flex justify-content-center mt-3 mb-3">
                            <div className="sign-up-btn" style={{
                                cursor: "pointer"
                            }} onClick={() => {
                                window.location = "#"
                            }}>
                                Sign Up now (Free)
                            </div>
                    </div>
                    {
                        window.location.hostname !== "accountstory.com" &&
                        <div>
                            <hr/>
                            <div className="mt-3">
                                <div style={{
                                    color: "#232F5E",
                                    fontSize: "18px",
                                    fontWeight: "700"
                                }}>
                                    $499 per month per AI SDR
                                </div>
                                <div className="mt-2" style={{
                                    fontSize: "17px"
                                }}>
                                    <b>+ $99</b> per lead - only pay after you approve each lead!
                                </div>
                                <div style={{
                                    fontSize: "17px"
                                }}>
                                    <b>+ 50%</b> discount automatically applied to the base fee during exploration
                                    months with no leads
                                </div>
                            </div>
                        </div>
                    }
                    <hr />
                    <div className="features" style={{
                        fontSize: "16px"
                    }}>
                        {
                            features.map(feature => {
                                return(
                                    <div className="feature d-flex align-items-center mt-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
                                            <path d="M9.5 1.5C8.01664 1.5 6.5666 1.93987 5.33323 2.76398C4.09986 3.58809 3.13856 4.75943 2.57091 6.12987C2.00325 7.50032 1.85472 9.00832 2.14411 10.4632C2.4335 11.918 3.14781 13.2544 4.1967 14.3033C5.2456 15.3522 6.58197 16.0665 8.03683 16.3559C9.49168 16.6453 10.9997 16.4968 12.3701 15.9291C13.7406 15.3614 14.9119 14.4001 15.736 13.1668C16.5601 11.9334 17 10.4834 17 9C17 8.01509 16.806 7.03982 16.4291 6.12987C16.0522 5.21993 15.4997 4.39314 14.8033 3.6967C14.1069 3.00026 13.2801 2.44781 12.3701 2.0709C11.4602 1.69399 10.4849 1.5 9.5 1.5ZM13.0325 7.2825L9.2825 11.0325C9.21278 11.1028 9.12983 11.1586 9.03844 11.1967C8.94704 11.2347 8.84901 11.2543 8.75 11.2543C8.651 11.2543 8.55297 11.2347 8.46157 11.1967C8.37018 11.1586 8.28723 11.1028 8.2175 11.0325L6.7175 9.5325C6.57628 9.39127 6.49693 9.19973 6.49693 9C6.49693 8.80027 6.57628 8.60873 6.7175 8.4675C6.85873 8.32627 7.05028 8.24693 7.25 8.24693C7.44973 8.24693 7.64128 8.32627 7.7825 8.4675L8.75 9.4425L11.9675 6.2175C12.1087 6.07627 12.3003 5.99693 12.5 5.99693C12.6997 5.99693 12.8913 6.07627 13.0325 6.2175C13.1737 6.35873 13.2531 6.55027 13.2531 6.75C13.2531 6.94973 13.1737 7.14127 13.0325 7.2825Z" fill="#26DE81"/>
                                        </svg>&nbsp;&nbsp;&nbsp;
                                        <div>
                                            {parse(feature)}
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className="note-section">
                        <b>No hidden fees. No long-term contracts.</b> Just qualified leads and a dedicated team to help you
                        grow. AI SDR includes up to 25,000 sent emails and up to 200 daily unique verified prospects.
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Pricing;