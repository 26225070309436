import React from "react";
import MainBanner from "./MainBanner";
import OurClients from "./Clients";
import Features from "./Features";
import Competitors from "./Competitors";
import Pricing from "./Pricing";
import Footer from "./Footer";
import UnparalledFeatures from "./UnparalledFeatures";

const HustleBotLandingPage = () => {
    return (
        <div className="hustle-bot-landing-page-container position-absolute w-100">
            <link
                href="//fonts.googleapis.com/css?family=Poppins:300,400,500,600,700,800,900"
                rel="stylesheet"
            ></link>
            <div className="position-absolute left-background-design" style={{
                left: 0,
                top: 0
            }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="308" height="161" viewBox="0 0 308 161" fill="none">
                    <path d="M123.5 154C25.5819 154 -54 73.0994 -54 -27C-54 -127.098 25.6182 -208 123.5 -208C221.418 -208 301 -127.099 301 -27L301 154L123.5 154Z" stroke="white" stroke-opacity="0.5" stroke-width="14"/>
                </svg>
            </div>
            <div className="position-absolute right-background-design" style={{
                right: 0
            }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="188" height="369" viewBox="0 0 188 369" fill="none">
                    <path opacity="0.3" d="M7 184.5C7 86.5819 87.9006 7 188 7C288.098 7 369 86.6182 369 184.5C369 282.418 288.099 362 188 362L7 362L7 184.5Z" stroke="#377DFF" stroke-width="14"/>
                </svg>
            </div>
            <div className="w-100">
                <div style={{
                    background: "radial-gradient(circle at top right, rgb(255, 255, 255) 5%,  rgba(74, 155, 239, 0.2) 50%, rgb(255, 255, 255) 80%),\n" +
                        "radial-gradient(circle at top left, rgba(74, 155, 239, 0.2) 30%, rgb(255, 255, 255) 90%)"
                }}>
                    <div className="d-flex justify-content-center">
                        <div className="d-flex justify-content-center section-container pl-4 pr-4">
                            <MainBanner/>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center clients-container">
                        <div className="d-flex justify-content-center section-container">
                            <OurClients/>
                        </div>
                    </div>
                </div>
                <div className="d-flex justify-content-center pl-4 pr-4">
                    <div className="d-flex justify-content-center section-container"
                         style={{
                             maxWidth: "1151px",
                    }}>
                        <UnparalledFeatures/>
                    </div>
                </div>
                <div className="position-relative w-100" style={{
                    background: "radial-gradient(circle at center top, rgba(55, 125, 255, 0.3) 0%, rgba(98, 212, 243, 0.2) 40%, rgb(230, 240, 255), rgb(255, 255, 255))"
                }}>
                    <div className="position-absolute" style={{
                        top: 0,
                        left: 0
                    }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="214" height="229" viewBox="0 0 214 229" fill="none">
                            <path opacity="0.1" d="M78.0944 216.707C8.33295 235.399 -63.8677 192.96 -82.9986 121.562C-102.129 50.1658 -60.7955 -22.6958 8.93991 -41.3814C78.7013 -60.0739 150.902 -17.6346 170.033 53.7629L204.61 182.807L78.0944 216.707Z" stroke="#377DFF" stroke-width="14"/>
                        </svg>
                    </div>
                    <Features/>
                </div>
                <div className="d-flex justify-content-center position-relative pl-4 pr-4">
                    <div className="d-flex justify-content-center" style={{width: "967px"}}>
                        <Competitors/>
                    </div>
                </div>
                <div className="d-flex justify-content-center pl-4 pr-4" id="hustle-bot__pricing">
                    <div className="d-flex justify-content-center" style={{
                        maxWidth: "1038px"
                    }}>
                        <Pricing/>
                    </div>
                </div>
            </div>

            <Footer/>
        </div>
    )
}

export default HustleBotLandingPage;