import React from "react";
import TargetingContentImage from "../../../../../assets/images/targeting-content.svg";

const Targeting = () => {
    return (
        <div style={{
            width: "885px"
        }}>
            <center>
                Target anyone with with use. You unlock a global dataset of over 200 million contacts. From executives
                and decision-makers to niche professionals, our database empowers your outreach with unparalleled
                precision and scalability.
            </center>

            <div style={{
                marginTop: "27px"
            }}>
                <img src={TargetingContentImage} alt="Messaging Content Image" style={{
                    maxWidth: "100%",
                    borderTopRightRadius: "30px",
                    borderTopLeftRadius: "30px",
                }}/>
            </div>
        </div>
    )
}

export default Targeting;