import React, {useState} from "react";
import SmartLeadLogo from "../../../../assets/images/smartlead.png";

const Competitors = () => {
    const competitorsTitle = ["Smartlead.AI", "Instantly"];
    const [selectedCompetitor, setSelectedCompetitor] = useState(competitorsTitle[0]);

    const features = [
        {
            title: "Campaign Creation Autopilot",
            competitors: []
        },
        {
            title: "AI-Powered Automation",
            competitors: []
        },
        {
            title: "Collaboration Tools",
            competitors: []
        },
        {
            title: "Proactive Customer Support",
            competitors: []
        },
        {
            title: "User-Friendly Interface",
            competitors: ["Smartlead.AI", "Instantly"]
        },
        {
            title: "Advanced Personalization",
            competitors: ["Smartlead.AI", "Instantly"]
        },
        {
            title: "Comprehensive Analytics",
            competitors: ["Smartlead.AI", "Instantly"]
        },
        {
            title: "Scalable Campaigns",
            competitors: ["Smartlead.AI", "Instantly"]
        },
        {
            title: "Generous Free Trial",
            competitors: []
        }
    ]


    return (
        <div className="competitors__block position-relative">
            <div className="position-absolute w-100" style={{
                bottom: "0",
                height: "1px",
                background: 'linear-gradient(90deg, rgba(35, 47, 94, 0.00) 0%, #232F5E 37.53%, rgba(35, 47, 94, 0.00) 100%)',
                opacity: 0.3
            }} />
            <div className="header d-flex justify-content-center">
                <svg width="337" height="34" viewBox="0 0 337 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M33.6549 2.44L28.1189 25H20.4069L17.3029 11.176L14.1349 25H6.42291L0.950914 2.44H7.73491L10.3589 17.864L13.9109 2.44H20.7909L24.2469 17.736L26.8709 2.44H33.6549ZM48.1244 6.888C50.1724 6.888 51.7937 7.58133 52.9884 8.968C54.2044 10.3333 54.8124 12.1893 54.8124 14.536V25H48.5404V15.368C48.5404 14.344 48.2737 13.544 47.7404 12.968C47.2071 12.3707 46.4924 12.072 45.5964 12.072C44.6577 12.072 43.9217 12.3707 43.3884 12.968C42.8551 13.544 42.5884 14.344 42.5884 15.368V25H36.3164V1.32H42.5884V9.608C43.1431 8.79733 43.8897 8.14666 44.8284 7.656C45.7884 7.144 46.8871 6.888 48.1244 6.888ZM77.5819 7.048L66.1899 33.544H59.3739L63.6619 24.232L56.3019 7.048H63.2779L67.0539 17.224L70.7019 7.048H77.5819Z"
                        fill="#232F5E"/>
                    <path
                        d="M276.175 6.95024V11.2956H263.614V6.95024H276.175ZM266.466 1.95312H272.251V21.3984C272.251 21.9325 272.332 22.349 272.495 22.6477C272.658 22.9374 272.884 23.1411 273.174 23.2588C273.473 23.3765 273.817 23.4353 274.206 23.4353C274.478 23.4353 274.749 23.4127 275.021 23.3674C275.292 23.3131 275.501 23.2723 275.645 23.2452L276.555 27.5498C276.265 27.6403 275.858 27.7444 275.333 27.8621C274.808 27.9888 274.17 28.0658 273.418 28.0929C272.024 28.1472 270.802 27.9617 269.752 27.5362C268.711 27.1107 267.901 26.4499 267.321 25.5536C266.742 24.6574 266.457 23.5258 266.466 22.1589V1.95312Z"
                        fill="#232F5E"/>
                    <path
                        d="M251.056 28.2162C248.947 28.2162 247.122 27.7681 245.583 26.8718C244.054 25.9666 242.872 24.7082 242.039 23.0968C241.206 21.4764 240.79 19.598 240.79 17.4615C240.79 15.307 241.206 13.424 242.039 11.8126C242.872 10.1922 244.054 8.93382 245.583 8.0376C247.122 7.13232 248.947 6.67969 251.056 6.67969C253.165 6.67969 254.985 7.13232 256.515 8.0376C258.054 8.93382 259.24 10.1922 260.072 11.8126C260.905 13.424 261.322 15.307 261.322 17.4615C261.322 19.598 260.905 21.4764 260.072 23.0968C259.24 24.7082 258.054 25.9666 256.515 26.8718C254.985 27.7681 253.165 28.2162 251.056 28.2162ZM251.083 23.7351C252.043 23.7351 252.844 23.4635 253.487 22.9203C254.129 22.3681 254.614 21.6167 254.939 20.6662C255.274 19.7156 255.442 18.6338 255.442 17.4208C255.442 16.2077 255.274 15.1259 254.939 14.1754C254.614 13.2248 254.129 12.4734 253.487 11.9212C252.844 11.369 252.043 11.0929 251.083 11.0929C250.114 11.0929 249.3 11.369 248.639 11.9212C247.987 12.4734 247.494 13.2248 247.159 14.1754C246.833 15.1259 246.67 16.2077 246.67 17.4208C246.67 18.6338 246.833 19.7156 247.159 20.6662C247.494 21.6167 247.987 22.3681 248.639 22.9203C249.3 23.4635 250.114 23.7351 251.083 23.7351Z"
                        fill="#232F5E"/>
                    <path
                        d="M216.458 27.81V0H227.593C229.639 0 231.345 0.303267 232.712 0.909802C234.079 1.51634 235.107 2.35824 235.795 3.43552C236.483 4.50374 236.827 5.73492 236.827 7.12904C236.827 8.21537 236.609 9.17043 236.175 9.99423C235.74 10.809 235.143 11.4789 234.382 12.0039C233.631 12.5199 232.771 12.8866 231.802 13.1039V13.3754C232.862 13.4207 233.853 13.7194 234.776 14.2717C235.709 14.8239 236.465 15.5979 237.044 16.5937C237.623 17.5804 237.913 18.7573 237.913 20.1243C237.913 21.5999 237.546 22.917 236.813 24.0758C236.089 25.2255 235.016 26.1353 233.595 26.8052C232.174 27.4751 230.422 27.81 228.34 27.81H216.458ZM222.338 23.003H227.131C228.77 23.003 229.965 22.6907 230.716 22.0661C231.467 21.4324 231.843 20.5905 231.843 19.5404C231.843 18.7709 231.658 18.0919 231.286 17.5035C230.915 16.9151 230.386 16.4534 229.698 16.1184C229.019 15.7835 228.208 15.616 227.267 15.616H222.338V23.003ZM222.338 11.6373H226.697C227.502 11.6373 228.218 11.497 228.842 11.2164C229.476 10.9267 229.974 10.5193 230.336 9.99423C230.707 9.46917 230.893 8.84001 230.893 8.10674C230.893 7.10188 230.535 6.29166 229.82 5.67607C229.114 5.06048 228.109 4.75269 226.805 4.75269H222.338V11.6373Z"
                        fill="#232F5E"/>
                    <path
                        d="M203.001 28.2162C200.855 28.2162 199.008 27.7816 197.46 26.9126C195.921 26.0345 194.735 24.7942 193.903 23.1919C193.07 21.5805 192.653 19.6749 192.653 17.4751C192.653 15.3296 193.07 13.4466 193.903 11.8262C194.735 10.2057 195.908 8.94287 197.42 8.0376C198.94 7.13232 200.724 6.67969 202.77 6.67969C204.146 6.67969 205.427 6.90148 206.613 7.34506C207.808 7.7796 208.849 8.43592 209.736 9.31404C210.632 10.1922 211.329 11.2966 211.827 12.6273C212.325 13.949 212.574 15.4971 212.574 17.2714V18.8602H194.962V15.2753H207.129C207.129 14.4424 206.948 13.7046 206.586 13.0619C206.223 12.4191 205.721 11.9167 205.078 11.5546C204.445 11.1834 203.707 10.9978 202.865 10.9978C201.987 10.9978 201.208 11.2015 200.529 11.6089C199.859 12.0072 199.334 12.5459 198.954 13.2248C198.574 13.8947 198.379 14.6416 198.37 15.4654V18.8737C198.37 19.9058 198.56 20.7974 198.94 21.5488C199.33 22.3002 199.877 22.8796 200.584 23.287C201.29 23.6943 202.127 23.898 203.096 23.898C203.738 23.898 204.327 23.8075 204.861 23.6264C205.395 23.4454 205.852 23.1738 206.232 22.8117C206.613 22.4496 206.902 22.006 207.102 21.4809L212.452 21.834C212.18 23.1195 211.623 24.242 210.781 25.2016C209.949 26.1521 208.871 26.8945 207.55 27.4286C206.237 27.9536 204.721 28.2162 203.001 28.2162Z"
                        fill="#232F5E"/>
                    <path d="M188.86 0V27.81H183.075V0H188.86Z" fill="#232F5E"/>
                    <path
                        d="M178.862 6.95024V11.2956H166.302V6.95024H178.862ZM169.153 1.95312H174.938V21.3984C174.938 21.9325 175.02 22.349 175.183 22.6477C175.345 22.9374 175.572 23.1411 175.861 23.2588C176.16 23.3765 176.504 23.4353 176.893 23.4353C177.165 23.4353 177.437 23.4127 177.708 23.3674C177.98 23.3131 178.188 23.2723 178.333 23.2452L179.243 27.5498C178.953 27.6403 178.546 27.7444 178.021 27.8621C177.495 27.9888 176.857 28.0658 176.106 28.0929C174.712 28.1472 173.49 27.9617 172.44 27.5362C171.398 27.1107 170.588 26.4499 170.009 25.5536C169.429 24.6574 169.144 23.5258 169.153 22.1589V1.95312Z"
                        fill="#232F5E"/>
                    <path
                        d="M163.65 12.8989L158.354 13.2248C158.263 12.7722 158.069 12.3648 157.77 12.0027C157.471 11.6315 157.077 11.3373 156.588 11.1201C156.109 10.8937 155.534 10.7806 154.864 10.7806C153.968 10.7806 153.212 10.9707 152.596 11.3509C151.981 11.7221 151.673 12.22 151.673 12.8446C151.673 13.3425 151.872 13.7635 152.27 14.1075C152.669 14.4515 153.352 14.7276 154.321 14.9358L158.096 15.6962C160.124 16.1126 161.635 16.7826 162.631 17.7059C163.627 18.6293 164.125 19.8424 164.125 21.3451C164.125 22.7121 163.722 23.9116 162.916 24.9436C162.12 25.9756 161.024 26.7813 159.63 27.3607C158.245 27.931 156.647 28.2162 154.837 28.2162C152.076 28.2162 149.876 27.6413 148.237 26.4916C146.608 25.3329 145.653 23.7577 145.372 21.7661L151.062 21.4674C151.234 22.3093 151.65 22.952 152.311 23.3956C152.972 23.8301 153.818 24.0474 154.85 24.0474C155.864 24.0474 156.679 23.8527 157.295 23.4635C157.919 23.0652 158.236 22.5537 158.245 21.929C158.236 21.404 158.014 20.974 157.58 20.639C157.145 20.295 156.475 20.0325 155.57 19.8514L151.958 19.1317C149.921 18.7244 148.405 18.0183 147.409 17.0134C146.422 16.0085 145.929 14.7276 145.929 13.1705C145.929 11.8307 146.291 10.6765 147.015 9.70783C147.748 8.73919 148.776 7.99234 150.098 7.46727C151.428 6.94222 152.985 6.67969 154.769 6.67969C157.403 6.67969 159.476 7.23643 160.988 8.34992C162.509 9.46341 163.396 10.9797 163.65 12.8989Z"
                        fill="#232F5E"/>
                    <path
                        d="M135.884 18.9299V6.95312H141.669V27.8107H136.115V24.0221H135.898C135.427 25.2442 134.644 26.2264 133.549 26.9687C132.462 27.7111 131.136 28.0822 129.57 28.0822C128.176 28.0822 126.949 27.7654 125.89 27.1317C124.831 26.498 124.002 25.5973 123.405 24.4295C122.817 23.2616 122.518 21.863 122.509 20.2335V6.95312H128.293V19.2015C128.303 20.4327 128.633 21.4058 129.285 22.121C129.937 22.8362 130.81 23.1938 131.906 23.1938C132.603 23.1938 133.254 23.0353 133.861 22.7185C134.467 22.3926 134.956 21.9128 135.327 21.2791C135.708 20.6454 135.893 19.8623 135.884 18.9299Z"
                        fill="#232F5E"/>
                    <path
                        d="M94.0869 27.81V0H99.9667V11.4744H111.903V0H117.769V27.81H111.903V16.3221H99.9667V27.81H94.0869Z"
                        fill="#232F5E"/>
                    <circle cx="287.108" cy="14.1069" r="4.55224" fill="#232F5E"/>
                    <path d="M336.915 0.078125V28.1346H329.298V0.078125H336.915Z" fill="#232F5E"/>
                    <path
                        d="M305.881 28.1346H297.661L306.922 0.078125H317.334L326.594 28.1346H318.375L312.237 7.80462H312.018L305.881 28.1346ZM304.346 17.0655H319.799V22.7644H304.346V17.0655Z"
                        fill="#377DFF"/>
                </svg>
            </div>
            <div className="sub-header d-flex justify-content-center">
                Stands Out Among Competitors
            </div>
            <div className="description d-flex justify-content-center">
                <div style={{
                    maxWidth: "684px",
                    textAlign: "center"
                }}>
                    Discover how Hustlebot.Ai excels with advanced features, seamless integrations, and unparalleled
                    scalability for your business growth.
                </div>
            </div>
            <div className="competitors-title d-flex justify-content-center" style={{
                marginTop: "43px",
                cursor: "pointer"
            }}>

                {
                    competitorsTitle.map(competitor_title => {
                        return(
                            <div className="p-2 ml-2 d-flex align-items-center" style={{
                                fontWeight: "700",
                                fontSize: "16px",
                                cursor: "pointer",
                                borderBottom: selectedCompetitor === competitor_title ? "2px solid #377DFF" : "2px solid transparent",
                            }}
                                 onClick={() => {
                                     setSelectedCompetitor(competitor_title);
                                 }}
                            >
                                {competitor_title}
                            </div>
                        )
                    })
                }
            </div>

            <div className="comparison__block d-flex justify-content-center" style={{
                marginTop: "43px"
            }}>
                <table style={{
                    borderRadius: "20px",
                }}>
                    <tr className="d-flex">
                        <th className="d-flex justify-content-center position-relative feature-col" style={{
                            borderTopLeftRadius: "10px"
                        }}>
                            <div className="position-absolute left-0 w-100" style={{
                                height: "9px",
                                borderTopLeftRadius: "20px",
                                background: "#BCD3FF",
                                top: "0px"
                            }}/>
                            Features
                        </th>
                        <th className="d-flex justify-content-center position-relative hustle-bot-header">
                            <div className="position-absolute left-0 w-100" style={{
                                height: "9px",
                                background: "#377DFF",
                                top: "0px"
                            }}/>
                            HustleBot.AI
                        </th>
                        <th className="d-flex justify-content-center position-relative" style={{
                            borderRight: "2px solid #BCD3FF",
                            borderTopRightRadius: "10px"
                        }}>
                            <div className="position-absolute left-0 w-100" style={{
                                height: "9px",
                                background: "#6E58F1",
                                borderTopRightRadius: "20px",
                                top: "0px"
                            }}/>
                            {selectedCompetitor}
                        </th>
                    </tr>
                    {
                        features.map((feature, index) => {
                            return (
                                <>
                                    <tr className="feature-col--mobile">
                                        <td className="d-flex justify-content-center w-100" style={{
                                            borderRight: "2px solid #BCD3FF"
                                        }}>
                                            {feature.title}
                                        </td>
                                    </tr>
                                    <tr className="d-flex">
                                        <td
                                            style={{
                                                borderBottomLeftRadius: index === features.length - 1 ? "20px" : "0px"
                                            }}
                                            className="d-flex align-items-center feature-col">
                                            {feature.title}
                                        </td>
                                        <td className="d-flex justify-content-center">
                                            <svg width="25" height="24" viewBox="0 0 25 24" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M12.167 2C10.1892 2 8.25579 2.58649 6.61129 3.6853C4.9668 4.78412 3.68508 6.3459 2.9282 8.17317C2.17133 10.0004 1.97329 12.0111 2.35914 13.9509C2.745 15.8907 3.6974 17.6725 5.09593 19.0711C6.49445 20.4696 8.27628 21.422 10.2161 21.8079C12.1559 22.1937 14.1666 21.9957 15.9938 21.2388C17.8211 20.4819 19.3829 19.2002 20.4817 17.5557C21.5805 15.9112 22.167 13.9778 22.167 12C22.167 10.6868 21.9083 9.38642 21.4058 8.17317C20.9032 6.95991 20.1667 5.85752 19.2381 4.92893C18.3095 4.00035 17.2071 3.26375 15.9938 2.7612C14.7806 2.25866 13.4802 2 12.167 2ZM16.877 9.71L11.877 14.71C11.784 14.8037 11.6734 14.8781 11.5516 14.9289C11.4297 14.9797 11.299 15.0058 11.167 15.0058C11.035 15.0058 10.9043 14.9797 10.7824 14.9289C10.6606 14.8781 10.55 14.8037 10.457 14.71L8.457 12.71C8.26869 12.5217 8.16291 12.2663 8.16291 12C8.16291 11.7337 8.26869 11.4783 8.457 11.29C8.6453 11.1017 8.9007 10.9959 9.167 10.9959C9.4333 10.9959 9.68869 11.1017 9.877 11.29L11.167 12.59L15.457 8.29C15.6453 8.1017 15.9007 7.99591 16.167 7.99591C16.4333 7.99591 16.6887 8.1017 16.877 8.29C17.0653 8.4783 17.1711 8.7337 17.1711 9C17.1711 9.2663 17.0653 9.5217 16.877 9.71Z"
                                                    fill="#26DE81"/>
                                            </svg>
                                        </td>
                                        <td className="d-flex justify-content-center" style={{
                                            borderRight: "2px solid #BCD3FF",
                                            borderBottomRightRadius: index === features.length - 1 ? "20px" : "0px"
                                        }}>
                                            {
                                                feature.competitors.includes(selectedCompetitor) ?
                                                    <svg width="25" height="24" viewBox="0 0 25 24" fill="none"
                                                         xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M12.167 2C10.1892 2 8.25579 2.58649 6.61129 3.6853C4.9668 4.78412 3.68508 6.3459 2.9282 8.17317C2.17133 10.0004 1.97329 12.0111 2.35914 13.9509C2.745 15.8907 3.6974 17.6725 5.09593 19.0711C6.49445 20.4696 8.27628 21.422 10.2161 21.8079C12.1559 22.1937 14.1666 21.9957 15.9938 21.2388C17.8211 20.4819 19.3829 19.2002 20.4817 17.5557C21.5805 15.9112 22.167 13.9778 22.167 12C22.167 10.6868 21.9083 9.38642 21.4058 8.17317C20.9032 6.95991 20.1667 5.85752 19.2381 4.92893C18.3095 4.00035 17.2071 3.26375 15.9938 2.7612C14.7806 2.25866 13.4802 2 12.167 2ZM16.877 9.71L11.877 14.71C11.784 14.8037 11.6734 14.8781 11.5516 14.9289C11.4297 14.9797 11.299 15.0058 11.167 15.0058C11.035 15.0058 10.9043 14.9797 10.7824 14.9289C10.6606 14.8781 10.55 14.8037 10.457 14.71L8.457 12.71C8.26869 12.5217 8.16291 12.2663 8.16291 12C8.16291 11.7337 8.26869 11.4783 8.457 11.29C8.6453 11.1017 8.9007 10.9959 9.167 10.9959C9.4333 10.9959 9.68869 11.1017 9.877 11.29L11.167 12.59L15.457 8.29C15.6453 8.1017 15.9007 7.99591 16.167 7.99591C16.4333 7.99591 16.6887 8.1017 16.877 8.29C17.0653 8.4783 17.1711 8.7337 17.1711 9C17.1711 9.2663 17.0653 9.5217 16.877 9.71Z"
                                                            fill="#26DE81"/>
                                                    </svg> : <svg width="25" height="24" viewBox="0 0 25 24" fill="none"
                                                                  xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M12.833 2.25C10.9046 2.25 9.01958 2.82183 7.4162 3.89317C5.81282 4.96451 4.56314 6.48726 3.82519 8.26884C3.08723 10.0504 2.89415 12.0108 3.27036 13.9021C3.64656 15.7934 4.57516 17.5307 5.93872 18.8943C7.30228 20.2579 9.03957 21.1865 10.9309 21.5627C12.8222 21.9389 14.7826 21.7458 16.5642 21.0078C18.3458 20.2699 19.8685 19.0202 20.9398 17.4168C22.0112 15.8134 22.583 13.9284 22.583 12C22.5803 9.41498 21.5522 6.93661 19.7243 5.10872C17.8964 3.28084 15.418 2.25273 12.833 2.25ZM16.3636 14.4694C16.4333 14.5391 16.4886 14.6218 16.5263 14.7128C16.564 14.8039 16.5834 14.9015 16.5834 15C16.5834 15.0985 16.564 15.1961 16.5263 15.2872C16.4886 15.3782 16.4333 15.4609 16.3636 15.5306C16.294 15.6003 16.2112 15.6556 16.1202 15.6933C16.0291 15.731 15.9316 15.7504 15.833 15.7504C15.7345 15.7504 15.6369 15.731 15.5458 15.6933C15.4548 15.6556 15.3721 15.6003 15.3024 15.5306L12.833 13.0603L10.3636 15.5306C10.294 15.6003 10.2112 15.6556 10.1202 15.6933C10.0291 15.731 9.93156 15.7504 9.83301 15.7504C9.73447 15.7504 9.63688 15.731 9.54584 15.6933C9.45479 15.6556 9.37207 15.6003 9.30239 15.5306C9.2327 15.4609 9.17743 15.3782 9.13972 15.2872C9.10201 15.1961 9.08259 15.0985 9.08259 15C9.08259 14.9015 9.10201 14.8039 9.13972 14.7128C9.17743 14.6218 9.2327 14.5391 9.30239 14.4694L11.7727 12L9.30239 9.53063C9.16166 9.38989 9.0826 9.19902 9.08259 9C9.0826 8.80098 9.16166 8.61011 9.30239 8.46937C9.44312 8.32864 9.63399 8.24958 9.83301 8.24958C10.032 8.24958 10.2229 8.32864 10.3636 8.46937L12.833 10.9397L15.3024 8.46937C15.3721 8.39969 15.4548 8.34442 15.5458 8.3067C15.6369 8.26899 15.7345 8.24958 15.833 8.24958C15.9316 8.24958 16.0291 8.26899 16.1202 8.3067C16.2112 8.34442 16.294 8.39969 16.3636 8.46937C16.4333 8.53906 16.4886 8.62178 16.5263 8.71283C16.564 8.80387 16.5834 8.90145 16.5834 9C16.5834 9.09855 16.564 9.19613 16.5263 9.28717C16.4886 9.37822 16.4333 9.46094 16.3636 9.53063L13.8933 12L16.3636 14.4694Z"
                                                            fill="#FF7B73"/>
                                                    </svg>
                                            }
                                        </td>
                                    </tr>
                                </>
                            )
                        })
                    }

                </table>
            </div>
        </div>
    )
}

export default Competitors;