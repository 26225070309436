import React, {useEffect, useState} from "react";
import WarningSign from "../../assets/images/warning-sign.svg";
import accountRouteList from "../routes/Account";
import * as actions  from "../actions";
import AILoadingIcon from "../../assets/images/ai-loading-icon.svg";
import AICheckMark from "../../assets/images/ai-check-mark.svg";
import {format} from "date-fns";
import mixpanel from "mixpanel-browser";

let campaignStatus = [
  "audience",
  "delivery_method",
  "creative",
  "budget_and_launch"
];

export const isStepValid = (step, currentStatus) => {
  let completedIndex = campaignStatus.indexOf(currentStatus);
  let statuses = campaignStatus.slice(0, completedIndex + 2);
  return statuses.includes(step);
}

export const isIncompleteStep = (currentStatus, campaignData) => {
  switch (currentStatus) {
    case "audience":
      return audienceValidation(campaignData);
    case "delivery_method":
      return deliveryMethodValidation(campaignData);
    case "creative":
      return creativeValidation(campaignData);
    case "budget_and_launch":
      return budgetValidation(campaignData);
  }
};

const audienceValidation = (campaignData) => {
  let audience = campaignData.audience;

  return {
    campaign_name: campaignData.campaign_name.length === 0,
    company_website: campaignData.company_website.length === 0,
    geographies: audience.geographies.length === 0,
    company_size: audience.company_size.length === 0,
    departments: audience.departments.length === 0,
    industries: audience.industries.length === 0,
    tools: audience.tools.length === 0,
    titles: audience.titles.length === 0,
    custom_departments: audience.custom_departments.length === 0,
    custom_tools: audience.custom_tools.length === 0,
    custom_titles: audience.custom_titles.length === 0,
    custom_industries: audience.custom_industries.length === 0,
    custom_geographies: audience.custom_geographies.length === 0
  };
};

const deliveryMethodValidation = (campaignData) => {
  let delivery_method = campaignData.delivery_method;
  return {
    delivery_mode: !Object.values(delivery_method.delivery_mode).includes(true),
    profile_id: ["", null].includes(delivery_method.profile_id),
    send_leads_to: delivery_method.send_leads_to.length === 0
  };
};

const creativeValidation = (campaignData) => {
  let creative = campaignData.creative;
  return {
    product_name: creative.product_name.length === 0,
    company_description: creative.company_description.length === 0,
    problem: creative.problem.length === 0,
    //problem_for: creative.problem_for.length === 0,
    impact: creative.impact.length === 0,
    different_about_product: creative.different_about_product.length === 0
  };
};

export const budgetValidation = (campaignData) => {
  let budget = campaignData.budget;
  let budget_type = budget.budget_type;
  let minimumBudget = budget_type === "free_trial" ? 0 : 100;
  return {
    budget_type: !budget.budget_type,
    budget: budget.budget.length === 0 || budget.budget < minimumBudget
  };
};

export const getCampaignStatusToSend = (currentStatus, nextStatus) => {
  let currentStatusIndex = campaignStatus.indexOf(currentStatus);
  let nextStatusIndex = campaignStatus.indexOf(nextStatus);

  if (currentStatusIndex > nextStatusIndex) {
    return currentStatus;
  } else if (currentStatusIndex < nextStatusIndex) {
    return nextStatus;
  } else {
    return nextStatus;
  }
}

export const permitToActivateCampaign = (currentStatus) => {
  if (currentStatus === "creative") {
    return true;
  } else {
    return false;
  }
}

export const getFieldsCheckData = (campaignData, field_name, currentStep) => {
  let isFieldEmpty = false
  if (campaignData[currentStep][field_name].length == 0) {
    isFieldEmpty = true
  }
  return isFieldEmpty;
};

export const incompleteFieldCheck = (isInvalidField, fieldType) => {

  let rightPosition = ""
  if (fieldType == "input") {
    rightPosition = "9px"
  } else if (fieldType == "select") {
    rightPosition = "45px"
  }
  return (isInvalidField && <img src={WarningSign} alt="warning sign" style={{ position: "absolute", top: "8px", right: rightPosition }} title="Required Field" />);
}

export const checkCompleteStep = (campaignData, state) => {
  let incomplete = false;

  let audienceIncompleteStatus = state.incompleteFields.audience;
  let {
    campaign_name,
    company_website,
    titles,
    custom_titles,
    industries,
    custom_industries,
    custom_geographies,
    tools,
    custom_tools,
    departments,
    custom_departments,
    company_size,
    geographies,
  } = audienceIncompleteStatus;

  let isIncomplete =
    campaign_name ||
    company_website ||
    (titles && custom_titles) ||
    (industries && custom_industries) ||
    (tools && custom_tools) ||
    (departments && custom_departments) ||
    company_size ||
    (geographies && custom_geographies);

  if (isIncomplete) {
    incomplete = true;
  }

  let incompleteFields = { ...state.incompleteFields };
  delete incompleteFields["audience"];

  if (!incomplete) {
    for (let data of Object.values(incompleteFields)) {
      if (Object.values(data).includes(true)) {
        incomplete = true;
        break;
      }
    }
  }
  return (
    incomplete ||
    Object.values(
      isIncompleteStep("budget_and_launch", campaignData)
    ).includes(true)
  );
};

// Extract route
export const getRouteToNavigate = (path, params) => {
  let pathToNavigate = path;
  for (let [key, value] of Object.entries(params)) {
    pathToNavigate = pathToNavigate.replace(":" + key, value);
  }
  return pathToNavigate;
}

export const showEnableEditMessage = (state) => {
  return state.isCampaignEditable && !state.isEditModeEnabled;
}

export const getPointerEvent = (state) => {
  return state.isCampaignEditable && !state.isEditModeEnabled ? "none" : "auto";
}

export const actionToExecute = (state, navigate, submit) => {
  return state.isCampaignEditable && !state.isEditModeEnabled ? navigate() : submit();
}

export const scrollBackToTop = () => {
  document.documentElement.scrollTo({
    top: 0,
    behavior: "smooth"
  });
}

export const defaultImage = () => {
  return "http://www.clker.com/cliparts/n/T/5/z/f/Y/image-missing-th.png";
};

export const getCookie = (cookieName) => {
  let cookie = {};
  document.cookie.split(';').forEach(function (el) {
    let [key, value] = el.split('=');
    cookie[key.trim()] = value;
  })

  return cookie[cookieName];
}

export const capitalizeEveryWord = (str) => {
  if (str) {
    let splitStr = str.toString().toLowerCase().split(' ');
    for (let i = 0; i < splitStr.length; i++) {
      // You do not need to check if i is larger than splitStr length, as your for does that for you
      // Assign it back to the array
      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    // Directly return the joined string
    return splitStr.join(' ')
  }
}

export const unique = (value, index, self) => {
  return self.indexOf(value) === index
}

const mapByCategory = (records, category) => {
  return records.map(x => x[category]).filter(unique)
}

const arrangeRecordData = (records, stakeholderDepartment, categoryType, department, stakeholders) => {
  records.forEach(category => {
    let newRecords = filterRecordsByCategory(stakeholderDepartment, categoryType, category)
    stakeholders[department][`${category}s`] = newRecords
  })
}

const filterRecordsByCategory = (records, categoryType, category) => {
  return records.filter(x => x[categoryType] == category)
}

export const setRecordsData = (records, authorize_credit_access, decision_makers_count) => {
  let departments = mapByCategory(records, "department")
  let accountMapCategories = mapByCategory(records, "account_map_category")
  let stakeholderCategories = mapByCategory(records, "stakeholder_category")
  let stakeholders = {}
  let accountMaps = {}
  departments.forEach(department => {
    stakeholders[department] = {}
    accountMaps[department] = {}
    let stakeholderDepartment = records.filter(x => x.department == department)
    arrangeRecordData(stakeholderCategories, stakeholderDepartment, 'stakeholder_category', department, stakeholders)
    arrangeRecordData(accountMapCategories, stakeholderDepartment, 'account_map_category', department, accountMaps)
  })

  let stakeholderByCategory = {}
  stakeholderCategories.forEach(category => {
    let newRecords = filterRecordsByCategory(records, 'stakeholder_category', category)
    stakeholderByCategory[`${category}s`] = newRecords
  })

  let accountMapByCategory = {}
  accountMapCategories.forEach(category => {
    let newRecords = filterRecordsByCategory(records, 'account_map_category', category)
    accountMapByCategory[`${category}s`] = newRecords
  })

  let stakeholderData = {
    records: stakeholders, decision_makers_count: decision_makers_count,
    authorize_credit_access: authorize_credit_access, ...stakeholderByCategory
  }
  let accountMapData = { records: accountMaps, authorize_credit_access: authorize_credit_access, ...accountMapByCategory }
  return [stakeholderData, accountMapData]
}

export const capitalize = (str, lower = false) =>
  ((lower ? str.toLowerCase() : str) || "").replace(/(?:^|\s|["'([{])+\S/g, match => match.toUpperCase());


export const setDataForAccountMap = (selectedDepartments, accountMapData, departments, isVerifiedExecutiveLevelBuyers, isDirectorLevelBuyers) => {

  let verifiedExecutiveLevelBuyers = selectedDepartments.flatMap(x => ((accountMapData[x] || []).verified_executive_level_buyers || []))
  let directorLevelBuyers = selectedDepartments.flatMap(x => ((accountMapData[x] || []).director_level_buyers || []))
  let verifiedManagerialInfluencers = selectedDepartments.flatMap(x => ((accountMapData[x] || []).verified_managerial_influencers || []))
  let verifiedIndividualContributorInfluencers = selectedDepartments.flatMap(x => ((accountMapData[x] || []).verified_individual_contributor_influencers || []))
  let verifiedInfluencers = selectedDepartments.flatMap(x => ((accountMapData[x] || []).verified_influencers || []))
  let potentialBlockers

  if ((selectedDepartments.length - 1) == (departments.options || []).length) {
    potentialBlockers = (accountMapData["Procurement"] || {}).potential_blockers
  } else {
    potentialBlockers = selectedDepartments.flatMap(x => ((accountMapData[x] || []).potential_blockers || []))
  }

  // special condition for potential blockers
  // potential brokers are from defined departments
  let potentialBlockersDepartment = ["Finance", "Procurement", "IT", "Legal"]
  if (potentialBlockersDepartment.some(r => selectedDepartments.indexOf(r) >= 0)) {
    (potentialBlockers || []).forEach(x => {
      if (isVerifiedExecutiveLevelBuyers(x["position"])) {
        verifiedExecutiveLevelBuyers.push(x)
      } else if (isDirectorLevelBuyers(x["position"])) {
        directorLevelBuyers.push(x)
      } else {
        (verifiedIndividualContributorInfluencers || verifiedInfluencers).push(x)
      }
    })
    potentialBlockers = []
  }

  let responseData = {
    verifiedIndividualContributorInfluencers: verifiedIndividualContributorInfluencers,
    verifiedManagerialInfluencers: verifiedManagerialInfluencers,
    verifiedInfluencers: verifiedInfluencers,
    verifiedExecutiveLevelBuyers: verifiedExecutiveLevelBuyers,
    directorLevelBuyers: directorLevelBuyers, potentialBlockers: potentialBlockers,
    verifiedSupportingDepartments: []
  }

  return responseData;

}

export const setToolDataForAccountMap = (selectedToolValues, selectedDepartments, records, toolsValue, isCommonElementsPresent) => {
  let verifiedExecutiveLevelBuyers, directorLevelBuyers, verifiedManagerialInfluencers, verifiedIndividualContributorInfluencers, verifiedSupportingDepartments = [], potentialBlockers, verifiedInfluencers;
  if (selectedToolValues.length == 1 && selectedToolValues[0].label == "All") {
    verifiedExecutiveLevelBuyers = selectedDepartments.flatMap(x => ((records[x] || []).verified_executive_level_buyers || []))
    directorLevelBuyers = selectedDepartments.flatMap(x => ((records[x] || []).director_level_buyers || []))
    verifiedIndividualContributorInfluencers = selectedDepartments.flatMap(x => ((records[x] || []).verified_individual_contributor_influencers || []))
    verifiedInfluencers = selectedDepartments.flatMap(x => ((records[x] || []).verified_influencers || []))
    verifiedManagerialInfluencers = selectedDepartments.flatMap(x => ((records[x] || []).verified_managerial_influencers || []))
    potentialBlockers = (records["Procurement"] || {}).potential_blockers
  } else {
    toolsValue = selectedToolValues.map(x => x.value)

    verifiedExecutiveLevelBuyers = selectedDepartments.flatMap(x => ((records[x] || [])
      .verified_executive_level_buyers || []))
      .filter(dm => isCommonElementsPresent(Object.values(dm.source_companies).flat(Infinity), toolsValue))
      .filter(x => !["ceo", "chief executive officer"].includes(((x.position || '').toLowerCase())))
      .filter(x => x.is_core_department);


    directorLevelBuyers = selectedDepartments.flatMap(x => ((records[x] || []).director_level_buyers || []))
      .filter(dm => isCommonElementsPresent(Object.values(dm.source_companies).flat(Infinity), toolsValue))
      .filter(x => x.is_core_department);

    potentialBlockers = selectedDepartments.flatMap(x => ((records[x] || []).potential_blockers || []))
      .filter(dm => isCommonElementsPresent(Object.values(dm.source_companies).flat(Infinity), toolsValue))
      .filter(x => x.is_core_department);

    verifiedManagerialInfluencers = selectedDepartments.flatMap(x => ((records[x] || []).verified_managerial_influencers || []))
      .filter(dm => isCommonElementsPresent(Object.values(dm.source_companies).flat(Infinity), toolsValue))
      .filter(x => x.is_core_department);

    verifiedIndividualContributorInfluencers = selectedDepartments.flatMap(x => ((records[x] || []).verified_individual_contributor_influencers || []))
      .filter(dm => isCommonElementsPresent(Object.values(dm.source_companies).flat(Infinity), toolsValue))
      .filter(x => x.is_core_department);

    verifiedInfluencers = selectedDepartments.flatMap(x => ((records[x] || []).verified_influencers || []))
      .filter(dm => isCommonElementsPresent(Object.values(dm.source_companies).flat(Infinity), toolsValue))
      .filter(x => x.is_core_department);

  }

  let responseData = {
    verifiedIndividualContributorInfluencers: verifiedIndividualContributorInfluencers,
    verifiedManagerialInfluencers: verifiedManagerialInfluencers,
    verifiedInfluencers: verifiedInfluencers,
    verifiedExecutiveLevelBuyers: verifiedExecutiveLevelBuyers,
    directorLevelBuyers: directorLevelBuyers,
    potentialBlockers: potentialBlockers,
    verifiedSupportingDepartments: verifiedSupportingDepartments
  }

  return responseData;
}

export const getActiveStep = () => {

  let route = window.location.pathname;

  if (route.includes("audience")) {
    return "1";
  } else if (route.includes("delivery_method")) {
    return "2";
  } else if (route.includes("budget_and_launch")) {
    return "3"
  } else {
    return "1";
  }

}

const definedTities = ["COO", "CEO", "CTO", "CRO", "CSO","CIO","CPO","CFO","CMP","CCO", "UX", "UR", "CHRO", "CX", "VP", "AI", "HR", "GTM", "IT"]

const filterTitles = value =>
  definedTities.some(element => value.replace(/[{()}]/g, '').replace(',','').toUpperCase() === element);

export const  upperCaseDefinedWords = (position, needStringBreak = false, breakPoint = 50) => {
  if (position !== undefined) {
    let data = position.split(" ")
    let filterWords = data.filter(filterTitles)
    filterWords.map(x => {
      position = position.replace(x, x.toUpperCase())
    })
    let wordInsidebracket = (position.match(/\((.*)\)/) || []).pop() || ""

    if (wordInsidebracket.split(" ").length > 1){
      let filterPosition = position.replace(`(${wordInsidebracket})`, `(${capitalize(wordInsidebracket)})`)
      return breakPosition(filterPosition, needStringBreak, breakPoint)
    }else{
      return breakPosition(position, needStringBreak, breakPoint)
    }
  }
}

const breakPosition = (position, needStringBreak, breakPoint = 50 ) => {
  if(needStringBreak) {
    return position.length > breakPoint ? position.substring(0, breakPoint) + '...' : position
  }else{
    return  position
  }
}

export const titleFitToCard = (title ) => {
  if ((title || "").length > 15){
    let titleSplit = title.split(" ")
    return capitalizeEveryWord(titleSplit[0] + " " +(titleSplit[1] || [])[0] + ".")
  }else{
    return capitalizeEveryWord(title)
  }
}
export const changedForecastedReach = (budget, forecastedReach) => {
  let budgetValue = parseInt(budget) === 0 ? 100 : parseInt(budget);
  let reach = {
    lower_bound: budgetValue,
    upper_bound: Math.ceil(budgetValue / 0.4),
  };
  let estimatedLeads = {
    lower_bound: Math.floor((budgetValue * 2) / 100),
    upper_bound: Math.ceil((budgetValue * 4) / 100),
  };
  return {
    ...forecastedReach,
    reach: reach,
    estimated_leads: estimatedLeads,
  };
};


export const navigateToSection = (history, sectionToNavigate, id) => {
  let routeToNavigate = getRouteToNavigate(accountRouteList.campaign_manager[sectionToNavigate], {
    id: id
  });


  // Send url to segment
  // window.analytics.page(routeToNavigate);
  // history.push(routeToNavigate);

  trackPage(history, accountRouteList.campaign_manager[sectionToNavigate], routeToNavigate);
}

export const trackPage = (history, currentPage, navigateTo) => {
  actions.AccountActions.trackCurrentPage(currentPage).then(() => {
    history.push(navigateTo);
  });
}

// AI form start

// campaign survey questions that are answered using AI
const aiFormQuestions = [
  {
    question: "Please list all the potential titles of people you target",
    type: "titles",
    varName: "titles",
    step: 'audience',
    parsingScheme: 'list'
  },
  {
    question: "What is your target company size?",
    type: "company_size",
    varName: "company_size",
    step: 'audience',
    parsingScheme: 'list'
  }
]

// get email from cookies named 'ajs_user_id' set when identifying user
export const getEmailFromCookies = () => {
  if (getCookie('ajs_user_id') !== undefined) {
    return decodeURIComponent(getCookie('ajs_user_id'))
  }
}

export const getAiFormAnswers = async (companyBaseUrl, company_name, company_description,  email, campaignData, aiAnswersError, aiAnswersLoading, dispatch, maxRetries = 3, campaign_id = null) => {
  const fetchAnswer = async (aiFormQuestion, retries = 0) => {
    try {
      const res = await actions.AccountActions.getAutomatedFormAnswers({
        ai_form_id: 3,
        question_data: {
          question: aiFormQuestion.question,
          choice_set: aiFormQuestion.type,
        },
        form_context: {
          company: company_name,
          description: company_description
        },
        company_base_url: companyBaseUrl,
        email: email,
        parsing_scheme: aiFormQuestion.parsingScheme,
        campaign_id: campaign_id
      });

      if (res.data.error) {
        if (retries < maxRetries) {
          console.warn(`Retrying ${aiFormQuestion.type} (${retries + 1}/${maxRetries})`);
          return fetchAnswer(aiFormQuestion, retries + 1);
        } else {
          aiAnswersError = {
            ...aiAnswersError,
            [aiFormQuestion.type]: res.data.error
          };
          actions.AccountActions.setAiAnswersError(dispatch, { ...aiAnswersError });
        }
      } else {
        mixpanel.track("AI Form Completed Successfully", {});
        campaignData = {
          ...campaignData,
          ...(aiFormQuestion.step === 'audience' && {
            audience: {
              ...campaignData.audience,
              [aiFormQuestion.type]: res.data.answers,
            }
          }),
          ...(aiFormQuestion.step === 'creative' && {
            creative: {
              ...campaignData.creative,
              [aiFormQuestion.type]: res.data.answers,
            }
          })
        };
        actions.AccountActions.setCampaignData(dispatch, campaignData);
      }
    } catch (error) {
      if (retries < maxRetries) {
        console.warn(`Retrying ${aiFormQuestion.type} due to error (${retries + 1}/${maxRetries})`);
        return fetchAnswer(aiFormQuestion, retries + 1);
      } else {
        aiAnswersError = {
          ...aiAnswersError,
          [aiFormQuestion.type]: "Request failed after retries"
        };
        actions.AccountActions.setAiAnswersError(dispatch, { ...aiAnswersError });
      }
    } finally {
      aiAnswersLoading = {
        ...aiAnswersLoading,
        [aiFormQuestion.type]: false
      };
      actions.AccountActions.setAiAnswersLoading(dispatch, { ...aiAnswersLoading });
    }
  };

  // Loop through all questions and fetch answers asynchronously
  await Promise.all(aiFormQuestions.map((question) => fetchAnswer(question)));
};


// add ai loader besides the input field and show success check mark when answer is rendered
export const aiAnswersLoader = (question_type, state) => {
  if (state.account.isAiFormAnswers && state.account.aiFormAnswersLoading) {
    if (state.account.aiAnswersLoading[question_type]) {
      return (
          <div className='position-absolute' style={{top: '-5px', right: '0'}}>
            <img src={AILoadingIcon} className="float-right"/>
          </div>
      )
    } else {
      return (
          <div className='position-absolute' style={{top: '12px', right: '10px'}}>
            {
                (state.account.aiAnswersError[question_type] || []).length === 0 && (
                    <img src={AICheckMark} className="float-right"/>
                )
            }
          </div>
      )
    }
  }
}

export const changeTimestampToDate = (timestamp) => {
  return format(new Date(Number(timestamp) * 1000), 'yyyy-MM-dd');
}

export const changeTimestampToDateTime = (timestamp) => {
  return format(new Date(Number(timestamp) * 1000), "MMM dd, yyyy 'at' h:mm a");
}

export const formatTimestamp = (timestamp) => {
  const date = new Date(parseFloat(timestamp) * 1000);
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  return  new Intl.DateTimeFormat('en-US', options).format(date);
}

export const changeTimestamToMonthDayTime = (timestamp) => {
  timestamp = parseFloat(timestamp) * 1000;

  const date = new Date(timestamp);

  if (isNaN(date.getTime())) {
    return "Invalid date";
  }

  const optionsDate = { month: 'short', day: 'numeric' };
  const formattedDate = date.toLocaleDateString('en-US', optionsDate);

  const optionsTime = { hour: 'numeric', minute: 'numeric', hour12: true };
  const formattedTime = date.toLocaleTimeString('en-US', optionsTime);

  return `${formattedDate} at ${formattedTime}`;
}

export const changeTimestampToMonthDay = (timestamp) => {
  const date = new Date(timestamp * 1000);
  const monthAbbreviations = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  const month = monthAbbreviations[date.getMonth()];
  const day = date.getDate();

  return `${month} ${day}`;
}

export const convertActiveRecordToMonthDateTime = (activeRecordDateTime) => {
  const date = new Date(activeRecordDateTime);

  if (isNaN(date.getTime())) {
    return "Invalid date";
  }

  const optionsDate = { month: 'short', day: 'numeric' };
  const formattedDate = date.toLocaleDateString('en-US', optionsDate);

  const optionsTime = { hour: 'numeric', minute: 'numeric', hour12: true };
  const formattedTime = date.toLocaleTimeString('en-US', optionsTime);

  return `${formattedDate} at ${formattedTime}`;
}


export const convertActiveRecordToDateMonth = (dateTimeString) => {
  const date = new Date(dateTimeString);
  const monthAbbreviations = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  const month = monthAbbreviations[date.getMonth()];
  const day = date.getDate();
  const formattedDay = day.toString().padStart(2, '0');
  return `${month} ${formattedDay}`;
}

export const truncateText = (text, truncate_length) => {
  return text.length > truncate_length ? text.substring(0, truncate_length) + "..." : text;
}


export const booleanToString = (value) => {
  if (typeof value === 'boolean') {
    return value ? "Yes" : "No";
  }
  return null;
}

export const removeHtmlTags = (str) => {
  return str.replace(/<[^>]*>/g, '');
}

export const get12HourFormat = (time) => {
  return time > 12 ? `${time - 12}` + 'PM' : `${time} AM`
}

export const isValidLink = (str) => {
  const urlRegex = /^(https?:\/\/(?:www\.)?[a-zA-Z0-9-]+(?:\.[a-zA-Z]{2,})+(?:\/[^\s]*)?)$/;
  return urlRegex.test(str);
}

export const insertStringAtIndex = (originalString, newString, index)  => {
  return originalString.substring(0, index) + newString + originalString.substring(index);
}

export const convertDateToUsersTimezone = (next_send_date, next_send_date_timezone) => {
  if (next_send_date !== null && next_send_date_timezone !== null) {
    const date = new Date(next_send_date);
    const dateOptions = {year: 'numeric', month: 'long', day: 'numeric'};
    const timeOptions = {hour: 'numeric', minute: 'numeric', hour12: true, timeZoneName: 'short'};

    const formattedDate = new Intl.DateTimeFormat('en-US', dateOptions).format(date);
    const formattedTime = new Intl.DateTimeFormat('en-US', {
      ...timeOptions,
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
    }).format(date);
    return `${formattedDate} at ${formattedTime}`;
  } else {
    return ""
  }
}


export const scanHTMLString = (htmlString) => {
  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = htmlString;

  tempDiv.querySelectorAll('a').forEach(link => {
    const href = link.getAttribute('href');
    if (href && !href.startsWith('https://') && !href.startsWith('http://')) {
      link.setAttribute('href', 'https://' + href);
    }
  });

  return tempDiv.innerHTML;
};


export const getAbbreviatedNumber = (original_number) => {
  const originalNumber = Number(original_number);
  if (originalNumber < 1000) {
    return originalNumber.toString();
  } else if (originalNumber < 1000000) {
    return (originalNumber / 1000).toFixed(1) + 'k';
  } else if (originalNumber < 1000000000) {
    return (originalNumber / 1000000).toFixed(1) + ' M';
  } else {
    return (originalNumber / 1000000000).toFixed(1) + ' B';
  }
}

const validHTMLTags = [
  'a', 'abbr', 'address', 'area', 'article', 'aside', 'audio', 'b', 'base', 'bdi', 'bdo',
  'blockquote', 'body', 'br', 'button', 'canvas', 'caption', 'cite', 'code', 'col', 'colgroup',
  'data', 'datalist', 'dd', 'del', 'details', 'dfn', 'dialog', 'div', 'dl', 'dt', 'em', 'embed',
  'fieldset', 'figcaption', 'figure', 'footer', 'form', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'head',
  'header', 'hr', 'html', 'i', 'iframe', 'img', 'input', 'ins', 'kbd', 'label', 'legend', 'li',
  'link', 'main', 'map', 'mark', 'meta', 'meter', 'nav', 'noscript', 'object', 'ol', 'optgroup',
  'option', 'output', 'p', 'param', 'picture', 'pre', 'progress', 'q', 'rp', 'rt', 'ruby', 's',
  'samp', 'script', 'section', 'select', 'small', 'source', 'span', 'strong', 'style', 'sub',
  'summary', 'sup', 'svg', 'table', 'tbody', 'td', 'template', 'textarea', 'tfoot', 'th', 'thead',
  'time', 'title', 'tr', 'track', 'u', 'ul', 'var', 'video', 'wbr'
];

export const sanitizeHTMLString = (inputString) => {
  const tagRegex = /<\/?([a-zA-Z]+)(?:\s[^>]*)?>|<[^>]+>/g;

  let formattedString = inputString.replace(/\n/g, '<br />').replaceAll('<br/>', '<br />');

  return formattedString.replace(tagRegex, (match, tagName) => {
    if (tagName && validHTMLTags.includes(tagName.toLowerCase())) {
      return match;
    } else {
      return match.replace(/</g, '&lt;').replace(/>/g, '&gt;');
    }
  });
}

export const isValidWebsite = (url) => {
  const pattern = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(:\d{1,5})?(\/.*)?$/;
  return pattern.test(url);
};

export const useTypingEffect = (
    phrases,
    speed = 50,
    backSpeed = 50,
    delay = 1000,
    reverse = true,
    loop = true
) => {
  const [placeholder, setPlaceholder] = useState("");
  const [phraseIndex, setPhraseIndex] = useState(0);
  const [charIndex, setCharIndex] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    if (phraseIndex >= phrases.length && !loop) return; // Stop if looping is disabled

    const currentPhrase = phrases[phraseIndex] || "";

    const updatePlaceholder = () => {
      setPlaceholder((prev) =>
          isDeleting ? currentPhrase.substring(0, charIndex - 1) : currentPhrase.substring(0, charIndex + 1)
      );

      if (!isDeleting && charIndex === currentPhrase.length) {
        if (reverse) {
          setTimeout(() => setIsDeleting(true), delay);
        } else {
          setPhraseIndex((prev) => (loop ? (prev + 1) % phrases.length : prev + 1));
          setCharIndex(0);
        }
      } else if (isDeleting && charIndex === 0) {
        setIsDeleting(false);
        setPhraseIndex((prev) => (loop ? (prev + 1) % phrases.length : prev + 1));
      }

      setCharIndex((prev) => (isDeleting ? prev - 1 : prev + 1));
    };

    const timeout = setTimeout(updatePlaceholder, isDeleting ? backSpeed : speed);

    return () => clearTimeout(timeout);
  }, [charIndex, isDeleting, phraseIndex, phrases, speed, backSpeed, delay, reverse, loop]);

  return placeholder;
};

export const redirectedFromLandingPage = () => {
  return localStorage.getItem("redirect_from_landing_page") === "true";
}



