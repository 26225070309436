import React from "react";
import AiPoweredHyperPersonalizationLogo from "../../../../assets/images/ai-powered-hyper-personalization.svg";
import HyperPersonalizationLogo from "../../../../assets/images/hyper-personalization.svg";
import FiftyMillionContractLogo from "../../../../assets/images/50-million-contract.svg";
import GuaranteedDeliverabilityLogo from "../../../../assets/images/guaranteed-deliverability.svg";
import IntegratedMailboxLogo from "../../../../assets/images/integrated-mailbox.svg";
import ReportingAnalyticsLogo from "../../../../assets/images/reporting-analytics-fingertips.svg";
import PayForResultLogo from "../../../../assets/images/pay-for-results.svg";
import Slider from "react-slick";
import Carousel from 'react-bootstrap/Carousel';

const UnparalledFeatures = () => {

    const featuresSet = [
        {
            title: "AI-Powered Hyper-Personalization",
            image: AiPoweredHyperPersonalizationLogo,
            description: "Today, personalizing outreach is critical to standing out in a crowded inbox. Our tool automatically hyper-personalizes messages to boost engagement with your audience. These AI-driven messages capture attention, increasing open rates, clicks, and replies for more effective outreach."
        },
        {
            title: "Global Reach with 200M+ contacts",
            image: FiftyMillionContractLogo,
            description: "With our tool, you gain access to a global dataset of over 275 million contacts. Whether you're targeting executives, decision-makers, or niche professionals, our database empowers your outreach efforts with unmatched precision and scale."
        }, {
            title: "Guaranteed Deliverability",
            image: HyperPersonalizationLogo,
            description: "Reaching decision-makers is key, and deliverability issues can block your success. Our system ensures emails land in inboxes, not spam, so you connect with the right people. We identify champions, influencers, and buyers, streamlining your prospecting."
        }, {
            title: "Integrated Mailbox with AI Detection",
            image: IntegratedMailboxLogo,
            description: "Managing outreach can be overwhelming with multiple tools, leading to missed opportunities. Our integrated mailbox consolidates all responses in one place. AI organizes replies—positive responses, objections, and auto-replies—while surfacing high-priority leads so you can focus on converting."
        }, {
            title: "Pay for Results",
            image: PayForResultLogo,
            description: "We believe in results, so part of our pricing is tied to performance. You only pay for what works—when we generate real opportunities, you see value. This alignment ensures our success is fully linked to yours."
        }, {
            title: "Reporting & Analytics at Your Fingertips",
            image: ReportingAnalyticsLogo,
            description: "Get real-time insights into every stage of your campaigns, with full visibility into opens, clicks, and replies. Our analytics provide the data you need to refine outreach, optimize performance, and drive better results over time."
        }
    ]


    return (
        <div className="unparalled-feature-set__block w-100 position-relative">
            <div className="position-absolute w-100" style={{
                top: "0",
                height: "1px",
                background: 'linear-gradient(90deg, rgba(35, 47, 94, 0.00) 0%, #232F5E 37.53%, rgba(35, 47, 94, 0.00) 100%)',
                opacity: 0.3
            }}/>
            <div className="header d-flex">
                Unparalled Feature Set
            </div>
            <div className="unparalled-features__block--desktop ml-0 mr-0">
                {
                    featuresSet.map(feature => {
                        return (
                            <div className="feature">
                                <div className="d-flex">
                                    <div>
                                        <img src={feature.image} alt={feature.title}/>
                                    </div>
                                    <div style={{
                                        marginLeft: "20px"
                                    }}>
                                        <div className="feature-title">
                                            {feature.title}
                                        </div>
                                        <div className="mt-2 feature-description" style={{
                                            color: "rgba(0, 0, 0, 0.64)",
                                            fontSize: "16px",
                                            fontWeight: 400
                                        }}>
                                            {feature.description}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>

            <div className="unparalled-features__block--mobile" style={{
                marginTop: "33px"
            }}>
                <Carousel interval={null}>
                    {
                        featuresSet.map(feature => {
                            return (
                                <Carousel.Item>
                                    <div className="feature">
                                        <div>
                                            <div>
                                                <img src={feature.image} alt={feature.title} style={{
                                                    height: "48px"
                                                }}/>
                                            </div>
                                            <div>
                                                <div className="feature-title" style={{
                                                    marginTop: "15px"
                                                }}>
                                                    {feature.title}
                                                </div>
                                                <div className="mt-2 feature-description" style={{
                                                    color: "rgba(0, 0, 0, 0.64)",
                                                    fontSize: "16px",
                                                    fontWeight: 400
                                                }}>
                                                    {feature.description}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Carousel.Item>
                            )
                        })
                    }
                </Carousel>
            </div>
        </div>
    )
}

export default UnparalledFeatures;